import React, { useState, useEffect, useRef } from 'react';
import { ChevronDown, ChevronUp, FileText, TrendingUp, Bot, Waypoints, Gamepad2, Webhook,  BarChart2, ChartScatter , ShieldCheck, Gamepad, CheckCircle, Activity, Database, X,} from 'lucide-react';


const ResourceCard = ({ 
  Icon, 
  title, 
  description, 
  buttonText, 
  buttonLink,
  isCollapsible = false,
  showAcceptConditions = false,
  extraContent 
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    if (buttonLink) {
      window.open(buttonLink, '_blank');
    } else if (isCollapsible) {
      setIsOpen(!isOpen); // Toggle only this card's visibility
    }
  };

  return (
    <div className="bg-white p-8 rounded-lg shadow-md transition-all">
      <Icon className="w-16 h-16 text-green-500 mb-6" />
      <h3 className="text-2xl font-semibold mb-4">{title}</h3>
      <p className="text-gray-600 mb-6">{description}</p>
      <button 
        className="bg-green-500 text-white px-6 py-3 rounded-full hover:bg-green-600 transition"
        onClick={handleClick}
      >
        {buttonText}
      </button>
      {isCollapsible && isOpen && extraContent && (
        <div className="mt-4 p-4 bg-gray-100 rounded">
          {extraContent}
          {showAcceptConditions && (
            <div className="mt-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                />
                <span>I accept the Terms of Service</span>
              </label>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const HorizontalRoadmap = () => {
  const roadmapItems = [
    {
      phase: "01",
      title: "Foundation Launch",
      icon: <CheckCircle className="w-6 h-6" />,
      items: [
        "MVP launch",
        "Basic charting",
        "Financial simulations",
        "Basic AI features"
      ],
      status: "Completed"
    },
    {
      phase: "02",
      title: "Platform Enhancement",
      icon: <Database className="w-6 h-6" />,
      items: [
        "Additional data sources",
        "DeFAI analytics tools",
        "More AI features",
        "V2 website upgrade"
      ],
      status: "In Progress"
    },
    {
      phase: "03",
      title: "Portfolio Innovation",
      icon: <BarChart2 className="w-6 h-6" />,
      items: [
        "Advanced portfolio analytics",
        "Expanded web capabilities",
        "Greater accessibility across various platforms and languages",
        "DeFAI portfolio management agents"

      ],
      status: "Planned"
    },
    {
      phase: "04",
      title: "Advanced Features",
      icon: <Activity className="w-6 h-6" />,
      items: [
        "AI marketplace",
        "CEX listing",
        "Play to Train implementation",
        "Business and data analytics suite"
      ],
      status: "Upcoming"
    }
];

return (
  <div className="max-w-6xl mx-auto px-4">
    <h2 className="text-2xl md:text-3xl font-bold text-center mb-8 md:mb-16">Our Roadmap</h2>
    
    {/* Desktop Version */}
    <div className="hidden md:block relative">
      {/* Connecting line */}
      <div className="absolute top-8 left-0 right-0 h-1 bg-gradient-to-r from-blue-500 to-green-500" />
      
      <div className="flex justify-between relative">
        {roadmapItems.map((item, index) => (
          <div key={item.phase} className="relative px-4 w-1/4">
            {/* Vertical connecting line */}
            <div className="absolute top-8 left-1/2 w-1 h-8 bg-gradient-to-b from-blue-500 to-green-500 -translate-x-1/2" />
            
            {/* Phase number circle */}
            <div className="absolute top-4 left-1/2 -translate-x-1/2 w-8 h-8 rounded-full bg-gradient-to-r from-blue-500 to-green-500 flex items-center justify-center text-white font-bold text-sm">
              {item.phase}
            </div>

            <div className="mt-20 bg-white rounded-lg p-6 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
              <div className="text-green-500 mb-3">{item.icon}</div>
              <h3 className="text-lg font-semibold mb-4">{item.title}</h3>
              <ul className="space-y-2 text-sm">
                {item.items.map((listItem, i) => (
                  <li key={i} className="flex items-start">
                    <span className="w-1.5 h-1.5 bg-gradient-to-r from-blue-500 to-green-500 rounded-full mr-2 flex-shrink-0 mt-2" />
                    <span>{listItem}</span>
                  </li>
                ))}
              </ul>
              <div className={`mt-4 text-sm font-medium ${
                item.status === 'Completed' ? 'text-green-500' :
                item.status === 'In Progress' ? 'text-blue-500' :
                'text-gray-500'
              }`}>
                {item.status}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>

    {/* Mobile Version */}
    <div className="md:hidden space-y-8">
      {roadmapItems.map((item, index) => (
        <div key={item.phase} className="relative">
          {/* Timeline connector */}
          {index !== roadmapItems.length - 1 && (
            <div className="absolute left-4 top-16 bottom-0 w-0.5 bg-gradient-to-b from-blue-500 to-green-500" />
          )}

          <div className="flex items-start">
            {/* Phase number circle */}
            <div className="z-10 flex-shrink-0 w-8 h-8 rounded-full bg-gradient-to-r from-blue-500 to-green-500 flex items-center justify-center text-white font-bold text-sm">
              {item.phase}
            </div>

            {/* Content card */}
            <div className="ml-6 bg-white rounded-lg p-6 shadow-lg flex-grow">
              <div className="flex items-center mb-4">
                <div className="text-green-500 mr-3">{item.icon}</div>
                <h3 className="text-lg font-semibold">{item.title}</h3>
              </div>
              <ul className="space-y-3">
                {item.items.map((listItem, i) => (
                  <li key={i} className="flex items-start">
                    <span className="w-1.5 h-1.5 bg-gradient-to-r from-blue-500 to-green-500 rounded-full mr-2 flex-shrink-0 mt-2" />
                    <span className="text-sm">{listItem}</span>
                  </li>
                ))}
              </ul>
              <div className={`mt-4 text-sm font-medium ${
                item.status === 'Completed' ? 'text-green-500' :
                item.status === 'In Progress' ? 'text-blue-500' :
                'text-gray-500'
              }`}>
                {item.status}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);
};
const NavLink = ({ targetId, children, onNavigate }) => {
  const handleClick = () => {
    const element = document.getElementById(targetId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    if (onNavigate) {
      onNavigate();
    }
  };

  return (
    <button onClick={handleClick} className="text-white hover:text-green-300 transition">
      {children}
    </button>
  );
};


const FeatureCard = ({ Icon, title, description, details }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition">
      <Icon className="w-12 h-12 text-green-500 mb-4" />
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600 mb-4">{description}</p>
      <button 
        className="text-green-500 hover:text-green-600 flex items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? 'Hide Details' : 'More Details'}
        {isOpen ? <ChevronUp className="ml-2 w-4 h-4" /> : <ChevronDown className="ml-2 w-4 h-4" />}
      </button>
      {isOpen && (
        <div className="mt-4 p-4 bg-gray-100 rounded">
          {details}
        </div>
      )}
    </div>
  );
};





const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(isOpen ? contentRef.current.scrollHeight : 0);
    }
  }, [isOpen]);

  return (
    <div className="border border-gray-200 rounded-lg mb-4 overflow-hidden bg-white hover:shadow-md transition-shadow duration-300">
      <button
        className="w-full px-6 py-4 text-left flex justify-between items-center gap-4 bg-white hover:bg-gray-50 transition-colors duration-300"
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        <h4 className="text-lg font-semibold text-gray-800 flex-1">{question}</h4>
        <div className={`transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}>
          <ChevronDown className="w-5 h-5 text-green-500" />
        </div>
      </button>
      <div 
        style={{ height: `${contentHeight}px` }}
        className="transition-all duration-300 ease-in-out"
      >
        <div ref={contentRef} className="px-6 pb-4 text-gray-600">
          {typeof answer === 'string' ? (
            <p>{answer}</p>
          ) : (
            answer
          )}
        </div>
      </div>
    </div>
  );
};

const ExtendedFAQSection = ({ setFooterSection, setIsFooterPageOpen }) => {
  const faqItems = [
    {
      question: "What's the easiest way to purchase Glades ($GLDS) tokens?",
      answer: (
        <div className="space-y-2">
          <p>You can buy $GLDS on <a 
            href="https://app.uniswap.org" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-green-500 hover:text-green-600 underline">
            Uniswap
          </a></p>
          <ul className="list-disc pl-5 space-y-1">
            <li>Connect your wallet to Uniswap</li>
            <li>Search for $GLDS token</li>
            <li>Set your desired amount and confirm the transaction</li>
          </ul>
        </div>
      )
    },
    {
      question: "How is the platform's 5/5 tax structure utilized?",
      answer: (
        <div className="space-y-2">
          <p>We maintain a transparent 5/5 buy and sell tax structure:</p>
          <ul className="list-disc pl-5 space-y-1">
            <li>100% is reinvested into platform development</li>
            <li>This covers ongoing development, marketing initiatives, and platform maintenance</li>
          </ul>
        </div>
      )
    },
    {
      question: "What are the hardware requirements for training AI models?",
      answer: (
        <div className="space-y-2">
          <p>Training requirements vary based on model complexity:</p>
          <ul className="list-disc pl-5 space-y-1">
            <li>Small models can be trained on standard computers without GPU</li>
            <li>For larger models, you can access our cloud computing infrastructure</li>
            <li>We provide scalable solutions for all training needs</li>
          </ul>
        </div>
      )
    },
    {
      question: "What are the different ways to access the Glades platform?",
      answer: (
        <div className="space-y-2">
          <p>There are two convenient ways to access Glades:</p>
          <ul className="list-disc pl-5 space-y-1">
            <li>Via our API for seamless integration with your existing systems</li>
            <li>Through our dedicated application, available on <a 
              href="https://github.com/Gattic/gattic-installer" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-green-500 hover:text-green-600 underline">
              GitHub
            </a></li>
          </ul>
        </div>
      )
    },
    {
      question: "How can I get started with the Glades software?",
      answer: (
        <div className="space-y-2">
          <p>Getting started is straightforward with our code on <a 
            href="https://github.com/Gattic/glades-ml" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-green-500 hover:text-green-600 underline">
            GitHub
          </a></p>
          <ul className="list-disc pl-5 space-y-1">
            <li>Clone our repository</li>
            <li>Follow our step-by-step installation guide</li>
            <li>Get up and running with basic commands</li>
          </ul>
        </div>
      )
    },
    {
      question: "When will the Play to Train feature be available?",
      answer: (
        <div className="space-y-2">
          <p>Our innovative Play to Train feature is launching soon!</p>
          <ul className="list-disc pl-5 space-y-1">
            <li>Join our Discord community for exclusive early access</li>
            <li>Be first to know about launch dates and updates</li>
            <li>Full documentation and tutorials will be provided</li>
          </ul>
        </div>
      )
    },
    {
      question: "Which cryptocurrency wallets will be supported?",
      answer: (
        <div className="space-y-2">
          <p>We're implementing support for popular Web3 wallets:</p>
          <ul className="list-disc pl-5 space-y-1">
            <li>MetaMask integration</li>
            <li>Phantom wallet support</li>
            <li>Additional major wallet connections coming soon</li>
          </ul>
          <p className="mt-2 text-sm text-gray-500">Full wallet integration details will be announced shortly!</p>
        </div>
      )
    }
  ];

  return (
    <section id="faq" className="bg-gradient-to-b from-blue-50 to-white py-16">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">Frequently Asked Questions</h2>
            <p className="text-gray-600">Find answers to common questions about Glades AI and our services</p>
          </div>
          <div className="space-y-4">
            {faqItems.map((item, index) => (
              <FAQItem key={index} question={item.question} answer={item.answer} />
            ))}
          </div>
          <div className="text-center mt-12">
            <p className="text-gray-600 mb-4">Still have questions?</p>
            <button 
              className="bg-green-500 text-white px-6 py-3 rounded-full hover:bg-green-600 transition-colors duration-300 flex items-center gap-2 mx-auto"
              onClick={() => {
                setFooterSection('contact');
                setIsFooterPageOpen(true);
              }}
            >
              Contact Us
              <ChevronDown className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
const FooterPage = ({ isOpen, onClose, initialSection = 'privacy' }) => {
  const [activeSection, setActiveSection] = useState(initialSection);

  if (!isOpen) return null;

  const renderContent = () => {
    switch (activeSection) {

      case 'privacy':
        return (
          <div className="prose max-w-none">
            <h3 className="text-2xl font-semibold mb-4">Privacy Policy</h3>
            <p className="text-sm text-gray-600 mb-6">Last Updated: December 16, 2024</p>
            
            <div className="space-y-8">
              <section>
                <h4 className="text-xl font-medium mb-3">1. Introduction</h4>
                <p>This Privacy Policy explains how GladesAI ("we", "our", or "us") collects, uses, and protects your personal information. It applies to all services provided through our platform, including our AI development tools, model marketplace, and training systems.</p>
              </section>
     
              <section>
                <h4 className="text-xl font-medium mb-3">2. Information We Collect</h4>
                <p className="font-medium mb-2">Personal Information</p>
                <p>We collect information you provide directly, including:</p>
                <ul className="list-disc pl-6 mb-4">
                  <li>Email address and account credentials</li>
                  <li>Cryptocurrency wallet addresses</li>
                  <li>Payment and transaction information</li>
                  <li>Profile information</li>
                </ul>
                <p className="font-medium mb-2">Technical Data</p>
                <p>We automatically collect:</p>
                <ul className="list-disc pl-6">
                  <li>Usage statistics and interaction data</li>
                  <li>Device and browser information</li>
                  <li>IP addresses and location data</li>
                  <li>API usage patterns</li>
                </ul>
              </section>
     
              <section>
                <h4 className="text-xl font-medium mb-3">3. Model and Training Data</h4>
                <p>We respect the privacy of your AI models and training data:</p>
                <ul className="list-disc pl-6">
                  <li>Your models remain your intellectual property</li>
                  <li>Training data is used only for your specified purposes</li>
                  <li>Model analytics are collected for performance optimization</li>
                  <li>You control sharing and visibility settings</li>
                </ul>
              </section>
     
              <section>
                <h4 className="text-xl font-medium mb-3">4. How We Use Your Information</h4>
                <p>We use collected information to:</p>
                <ul className="list-disc pl-6">
                  <li>Provide and improve our services</li>
                  <li>Process transactions and rewards</li>
                  <li>Ensure platform security</li>
                  <li>Communicate important updates</li>
                  <li>Analyze and optimize performance</li>
                  <li>Comply with legal obligations</li>
                </ul>
              </section>
     
              <section>
                <h4 className="text-xl font-medium mb-3">5. Data Sharing and Third Parties</h4>
                <p>We may share information with:</p>
                <ul className="list-disc pl-6">
                  <li>Service providers and infrastructure partners</li>
                  <li>Payment processors and blockchain networks</li>
                  <li>Analytics and security services</li>
                  <li>Legal authorities when required</li>
                </ul>
              </section>
     
              <section>
                <h4 className="text-xl font-medium mb-3">6. Data Security</h4>
                <p>We implement industry-standard security measures to protect your information:</p>
                <ul className="list-disc pl-6">
                  <li>Encryption of sensitive data</li>
                  <li>Regular security audits</li>
                  <li>Access controls and monitoring</li>
                  <li>Secure data storage systems</li>
                </ul>
              </section>
     
              <section>
                <h4 className="text-xl font-medium mb-3">7. Your Rights and Choices</h4>
                <p>You have the right to:</p>
                <ul className="list-disc pl-6">
                  <li>Access your personal information</li>
                  <li>Correct inaccurate data</li>
                  <li>Request data deletion</li>
                  <li>Export your data</li>
                  <li>Opt-out of communications</li>
                  <li>Control model sharing settings</li>
                </ul>
              </section>
     
              <section>
                <h4 className="text-xl font-medium mb-3">8. Data Retention</h4>
                <p>We retain your information for as long as necessary to provide our services and comply with legal obligations. You may request deletion of your account and associated data at any time, subject to legal requirements and legitimate business interests.</p>
              </section>
     
              <section>
                <h4 className="text-xl font-medium mb-3">9. International Data Transfers</h4>
                <p>Your information may be transferred and processed in different countries. We ensure appropriate safeguards are in place for international data transfers in compliance with applicable laws.</p>
              </section>
     
              <section>
                <h4 className="text-xl font-medium mb-3">10. Children's Privacy</h4>
                <p>Our services are not intended for users under 18. We do not knowingly collect or maintain information from children.</p>
              </section>
     
              <section>
                <h4 className="text-xl font-medium mb-3">11. Changes to Privacy Policy</h4>
                <p>We may update this policy periodically. Significant changes will be notified through our platform or email. Continued use of our services after changes constitutes acceptance.</p>
              </section>
     
              <section>
                <h4 className="text-xl font-medium mb-3">12. Contact Information</h4>
                <p>For privacy-related questions:<br />
                Email: privacy@glades.ai<br />
                </p>
              </section>
            </div>
          </div>
        );
        
      case 'terms':
        return (
          <div className="prose max-w-none">
            <h3 className="text-2xl font-semibold mb-4">Terms of Service</h3>
            <p className="text-sm text-gray-600 mb-6">Last Updated: December 16, 2024</p>
            
            <div className="space-y-8">
              <section>
                <h4 className="text-xl font-medium mb-3">1. Acceptance of Terms</h4>
                <p>By accessing or using GladesAI's services, including our website, AI development platform, model marketplace, and associated tools (collectively, the "Services"), you agree to be bound by these Terms of Service ("Terms"). If you are using the Services on behalf of an organization, you represent that you have the authority to bind that organization to these Terms. You must be at least 18 years old to use our Services.</p>
              </section>
     
              <section>
                <h4 className="text-xl font-medium mb-3">2. Service Description</h4>
                <p>Glades provides AI development, training, and deployment services, including but not limited to neural network development and training tools, data analysis and processing capabilities, model marketplace and distribution platform, Play to Train™ training system, and API access and integration tools. Services may not be available in all jurisdictions.</p>
              </section>
     
              <section>
                <h4 className="text-xl font-medium mb-3">3. User Accounts and Security</h4>
                <p className="font-medium mb-2">Account Registration</p>
                <p>You must provide accurate, current, and complete information during registration. Maintain the security of your account credentials and notify us immediately of any unauthorized access. One person or entity per account unless explicitly authorized.</p>
                <p className="font-medium mt-4 mb-2">Account Security</p>
                <p>You are responsible for all activities under your account, including wallet security and cryptocurrency transactions. Implement reasonable security measures for API keys and access tokens. Regular security audits may be conducted to ensure compliance.</p>
              </section>
     
              <section>
                <h4 className="text-xl font-medium mb-3">4. Data Privacy and Protection</h4>
                <p className="font-medium mb-2">Data Processing</p>
                <p>We process data in accordance with our Privacy Policy and applicable laws. You retain ownership of your data and grant us necessary licenses to provide the Services. You are responsible for obtaining required consents for data processing.</p>
                <p className="font-medium mt-4 mb-2">Data Security</p>
                <p>We implement industry-standard security measures. You must not upload malicious code or conduct security attacks. Regular backups are recommended but not guaranteed.</p>
              </section>
     
              <section>
                <h4 className="text-xl font-medium mb-3">5. Intellectual Property</h4>
                <p>You retain full ownership rights to your custom AI models and input data. Open-source components are subject to their respective licenses. Our software, technology, and platform infrastructure remain our exclusive property. License to use platform features is non-transferable.</p>
              </section>
     
              <section>
                <h4 className="text-xl font-medium mb-3">6. Token and Financial Terms</h4>
                <p>Cryptocurrency transactions are irreversible. Users acknowledge market volatility risks and that there is no guarantee of token value. Users are responsible for their own tax implications and compliance with local regulations. Revenue sharing and reward distributions are subject to our current policies.</p>
              </section>
     
              <section>
                <h4 className="text-xl font-medium mb-3">7. Usage Rules and Restrictions</h4>
                <p>Users agree not to violate laws or regulations, infringe on intellectual property rights, conduct unauthorized security testing, reverse engineer the Services, exceed rate limits or API quotas, use for weapons development or military applications, or create discriminatory or harmful AI systems.</p>
              </section>
     
              <section>
                <h4 className="text-xl font-medium mb-3">8. Play to Train™ Specific Terms</h4>
                <p>Reward distribution subject to quality controls and minimum payout thresholds apply. Training contributions subject to review and must maintain minimum accuracy standards. Intentional degradation is prohibited.</p>
              </section>
     
              <section>
                <h4 className="text-xl font-medium mb-3">9. Model Deployment</h4>
                <p>Users are responsible for their deployed models, including compliance with applicable laws, maintaining appropriate security measures, and proper testing and validation. Models must not be used for illegal or harmful purposes.</p>
              </section>
     
              <section>
                <h4 className="text-xl font-medium mb-3">10. Liability and Warranties</h4>
                <p>Services provided "as is" without warranty. We are not liable for decisions made using our AI models, loss of data or profits, service interruptions, or third-party content or services. Maximum liability limited to fees paid. We are not responsible for cryptocurrency market risks or token value fluctuations.</p>
              </section>
     
              <section>
                <h4 className="text-xl font-medium mb-3">11. Changes to Terms</h4>
                <p>We may modify these terms with notice. Continued use constitutes acceptance. Material changes require explicit consent.</p>
              </section>
     
              <section>
                <h4 className="text-xl font-medium mb-3">12. Contact Information</h4>
                <p>For questions about these Terms:<br />
                Email: legal@glades.ai<br />
                </p>
              </section>
            </div>
          </div>
        );
        
      case 'contact':
        return (
          <div>
            <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
            <form onSubmit={(e) => {
              e.preventDefault();
              // Handle form submission
              console.log('Form submitted');
            }} className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  required
                />
              </div>
              <div>
                <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
                  Subject
                </label>
                <input
                  type="subject"
                  id="email"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  required
                  />
                  </div>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      required

                />
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                  Message
                </label>
                <textarea
                  id="message"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  rows="4"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition"
              >
                Send Message
              </button>
            </form>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-white z-50 overflow-y-auto">
      <div className="max-w-6xl mx-auto px-4 py-8">
        {/* Header with navigation and close button */}
        <div className="flex justify-between items-center mb-8 border-b pb-4">
          <nav className="flex space-x-6">
            <button
              onClick={() => setActiveSection('privacy')}
              className={`text-lg ${activeSection === 'privacy' ? 'text-green-500 font-semibold' : 'text-gray-600'}`}
            >
              Privacy Policy
            </button>
            <button
              onClick={() => setActiveSection('terms')}
              className={`text-lg ${activeSection === 'terms' ? 'text-green-500 font-semibold' : 'text-gray-600'}`}
            >
              Terms of Service
            </button>
            <button
              onClick={() => setActiveSection('contact')}
              className={`text-lg ${activeSection === 'contact' ? 'text-green-500 font-semibold' : 'text-gray-600'}`}
            >
              Contact Us
            </button>
          </nav>
          <button
            onClick={onClose}
            className="bg-gray-100 hover:bg-gray-200 text-gray-600 px-4 py-2 rounded-lg flex items-center space-x-2 transition"
          >
            <X className="w-5 h-5" />
            <span>Close</span>
          </button>
        </div>

        {/* Content area */}
        <div className="max-w-3xl mx-auto">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

const GallerySection = () => {
  const galleryItems = [
    {
      id: 1,
      image: "/images/ml.png", 
      title: "https://glades-ml.com",
      description: "Advanced neural network training visualization."
    },
    {
      id: 2,
      image: "/images/sharpe1.png",
      title: "Risk Metrics",
      description: "Sharpe ratio measures how much return an investment generates for each unit of risk taken."
    },
    {
      id: 3,
      image: "/images/graph.png",
      title: "Data Analytics",
      description: "Comprehensive data analysis tools."
    },
    {
      id: 4,
      image: "/images/sharpe.png",
      title: "Risk Metrics",
      description: "Beta measures an investment's volatility relative to the market."
    },
    {
      id: 5,
      image: "/images/ellipse.png",
      title: "EllipseCreator",
      description: "A fun tool where you can play around creating ellipses and heatmaps."
    }
  ];

  return (
    <section id="gallery" className="bg-blue-50 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl text-center mb-12">
          Gallery
        </h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {galleryItems.map((item) => (
            <div 
              key={item.id}
              className="group bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-all duration-300"
            >
              <div className="relative h-48 md:h-64 overflow-hidden p-3 bg-gradient-to-r from-blue-50 to-green-50">
                <div className="absolute inset-0 border-2 border-green-200 m-3 rounded-lg pointer-events-none"></div>
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-full h-full object-cover rounded-lg transform group-hover:scale-105 transition-transform duration-300 shadow-sm"
                />
              </div>
              <div className="p-6 border-t border-gray-100">
                <h3 className="text-xl font-semibold mb-2 text-gray-800">{item.title}</h3>
                <p className="text-gray-600">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <button
      onClick={scrollToTop}
      className={`fixed bottom-8 right-8 bg-green-500 text-white p-3 rounded-full shadow-lg transition-all duration-300 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 ${
        isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10 pointer-events-none'
      }`}
      aria-label="Back to top"
    >
      <ChevronUp className="w-6 h-6" />
    </button>
  );
};


function GladesWebsite() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFooterPageOpen, setIsFooterPageOpen] = useState(false);
  const [footerSection, setFooterSection] = useState('privacy');
  const playToTrainRef = useRef(null);
  const [email, setEmail] = useState('');
  const [subscribeStatus, setSubscribeStatus] = useState('');

  useEffect(() => {
  const styleTag = document.createElement('style');
  styleTag.innerHTML = `
    @keyframes fadeInSlideUp {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  `;
  document.head.appendChild(styleTag);

  return () => document.head.removeChild(styleTag);
}, []);




  const handleSubscribe = (e) => {
    e.preventDefault();
    console.log('Subscribed:', email);
    setSubscribeStatus('Thanks for subscribing!');
    setEmail('');

  };


  return (
    <div className="font-sans text-gray-600">
    <header className="bg-gradient-to-r from-blue-600 to-green-600 text-white p-2 md:p-4 fixed w-full z-50">
      <nav className="container mx-auto">
        <div className="flex items-center justify-between">
          {/* Logo */}
          <button 
            onClick={() => {
              const element = document.getElementById('home');
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
            }} 
            className="flex items-center hover:opacity-80 transition"
          >
            <img 
              src="logo-012.png"
              alt="GladesAI Logo" 
              className="w-8 h-8 md:w-16 md:h-12 object-contain"
            />
            <h1 className="text-lg md:text-3xl font-mediuml-2">Glades
            </h1>
          </button>
  
              
                {/* Desktop Navigation */}
                
      {/* Desktop Navigation */}
<div className="hidden md:flex items-center space-x-2 justify-end">
  <NavLink targetId="home">Home |</NavLink>
  <NavLink targetId="About">About |</NavLink>
  <NavLink targetId="play-to-train">Play to Train™ |</NavLink>
  <NavLink targetId="marketplace">Marketplace |</NavLink>
  <NavLink targetId="gallery">Gallery |</NavLink>
  <a 
    href="https://github.com/Gattic/glades-ml" 
    target="_blank" 
    rel="noopener noreferrer"
    className="text-white hover:text-green-300 transition"
  >
    GitHub |
  </a>
  <a 
    href="https://github.com/Gattic/litepaper/wiki/Litepaper" 
    target="_blank" 
    rel="noopener noreferrer"
    className="text-white hover:text-green-300 transition"
  >
    Litepaper |
  </a>
</div>
          {/* Hamburger menu button */}
          <button className="md:hidden p-1" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
        
        {/* Mobile Navigation Menu */}
        {/* First, adjust the container for the mobile menu */}
        <div className={`${isMenuOpen ? 'block' : 'hidden'} md:hidden mt-2 absolute top-full left-0 right-0 bg-gradient-to-r from-blue-600 to-green-600 shadow-lg`}>
    <div className="flex flex-col text-center py-2 space-y-4">
        <NavLink targetId="home" onNavigate={() => setIsMenuOpen(false)}>Home</NavLink>
        <NavLink targetId="About" onNavigate={() => setIsMenuOpen(false)}>About</NavLink>
        <NavLink targetId="play-to-train" onNavigate={() => setIsMenuOpen(false)}>Play to Train™</NavLink>
        <NavLink targetId="marketplace"onNavigate={() => setIsMenuOpen(false)}>Marketplace™</NavLink>
      <NavLink targetId="gallery" onNavigate={() => setIsMenuOpen(false)}>Gallery</NavLink>
        <a 
            href="https://github.com/Gattic/glades-ml" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-white hover:text-green-300 transition py-2 text-center"
            onClick={() => setIsMenuOpen(false)}
        >
            GitHub
        </a>
        <a 
            href="www.google.com" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-white hover:text-green-300 transition py-2 text-center"
            onClick={() => setIsMenuOpen(false)}
        >
            Litepaper
        </a>
    </div>
</div>
      </nav>
    </header>

<section 
  id="home" 
  className="relative bg-cover bg-center text-white min-h-screen flex items-center pt-20 md:pt-16"
  style={{ backgroundImage: `url('/images/background1.png')` }}
>
  <div className="absolute inset-0 bg-black opacity-50"></div>
  <div className="container mx-auto text-center relative z-10 px-4">
    <h2 className="mb-4 md:mb-6">
      <span className="text-3xl md:text-5xl font-medium text-green-400">Glades AI</span>



      <span className="block text-2xl md:text-4xl font-semibold mt-2 md:mt-4">Innovation in Machine Learning</span>
    </h2>
    <p className="text-lg md:text-xl mb-6 md:mb-8 max-w-2xl mx-auto">
    Agents & DeFAI Analytics   </p>
    <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
  <a 
    href="https://github.com/Gattic/gattic-installer"
    target="_blank"
    rel="noopener noreferrer"
    className="w-full md:w-auto bg-green-500 text-white px-4 py-2 rounded-full text-base font-semibold hover:bg-green-600 transition inline-block text-center"
  >
    Install Agent Creator
  </a>
  <a 
    href="https://app.uniswap.org/"
    target="_blank"
    rel="noopener noreferrer"
    className="w-full md:w-auto bg-blue-500 text-white px-4 py-2 rounded-full text-base font-semibold hover:bg-purple-600 transition inline-block text-center"
  >
    Buy $GLDS
  </a>
</div>
  </div>
</section>

<section 
        
        id="About" className="bg-blue-50 py-16"> 
        <div className="container mx-auto">
          <h2 className="text-3xl text-center mb-12">Products & Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          
          <FeatureCard 
              Icon={Bot} 
              title="AI Agents" 
              description="Create and deploy Agents to complete various tasks."
              details={
                <ul className="list-disc list-inside">
                  <li>Use one of the many AI in our marketplace to perform various tasks</li>
                  <li>Use Our AI agent to scan documents for sentiment</li>
                  <li>Revenue sharing for model creators</li>
                  <li>Continuous model updates and improvements</li>
                </ul>
              }
              />

            <FeatureCard 
              Icon={Webhook} 
              title="Web API" 
              description="Access the power of the Glades engine anywhere via HTTP or HTTPS. Utilize our advanced mathmatical and AI models."
              details={
                <ul className="list-disc list-inside">
                  <li>Supports CVS and PNG output</li>
                  <li>Realtime data</li>
                  <li>Connect your portolio</li>
                </ul>
              }
            />

            <FeatureCard 
            Icon={Gamepad2}
            title="Play to Train" 
            description="Utilize your video games processing power to earn money by training AI."
            details={
            <ul className="list-disc list-inside">
              <li>Earn money by playing video games </li>
              <li>Unreal and unity support (coming soon)</li>
              <li>VR support</li>
              <li>Developers can earn too buy adding Glades to their video game</li>
              </ul>
            }
          />
            <FeatureCard 
              Icon={TrendingUp} 
              title="Quantitative Research " 
              description="Advanced signals uniquely visualized. Enchance your perspective on the market with the power of Glades engine"
              details={
                <ul className="list-disc list-inside">
                  <li>Accessible via Web API, Discord and Telegram</li>
                  <li>Advanced forecasting and predictions</li>
                  <li>Integration with multiple data sources</li>
                </ul>
              }
            />

            <FeatureCard 
              Icon={Waypoints} // First import Microscope from lucide-react
              title="NNCreator" 
              description="A lightweight and portable GUI that allows you to create and manage neural networks."
              details={
                <ul className="list-disc list-inside">
                  <li>Supports 32 bit and 64 bit systems</li>
                  <li>Load and save neural network states locally on your computer</li>
                  <li>Locally deployable decentralised backend</li>
                  <li>Various customisation options and visualizations</li>
                </ul>
              }
            />
            <FeatureCard 
              Icon={ChartScatter} 
              title="Portfolio Analytics" 
              description="Connect your portfolio to receive various statistics and analytics."
              details={
               <ul className="list-disc list-inside">
                <li>Receive unique risk perameters for your portfolio</li>
                <li>unique visualizations of your portfolio</li>
                <li>Automated report generation and insights</li>
              </ul>
            }
          />
    
          </div>
        </div>
      </section>

      <section id="features" className="bg-blue-50 py-32"> {/* Changed background to light blue */}
  <div className="container mx-auto">
    <h2 className="text-4xl text-center mb-16">Our Vision</h2>
    <div className="bg-white p-20 rounded-lg shadow-md"> {/* Added this wrapper */}
      <div className="flex flex-col lg:flex-row items-center space-y-12 lg:space-y-0 lg:space-x-16">
        <div className="w-full lg:w-1/2">
          {/* Adjust text alignment for mobile */}
          <p className="text-xl text-gray-700 mb-8 text-left md:text-center">
            At Glades AI, we strive to broaden access to advanced AI technologies, making them attainable and actionable for people and businesses of all sizes.
          </p>
          <ul className="list-disc list-inside text-xl text-gray-700 space-y-4 text-left md:text-center">
            <li>Deploy AI agents to assist you</li>
            <li>Earn money to train AI</li>
            <li>Advanced analytics and visualizations for all datasets</li>
          </ul>
        </div>
        <div className="w-full lg:w-1/2 flex justify-center">
          <img src="images\file-8urtAwBpKYn5FcnsCokQWq.jpg" alt="Our Vision" className="rounded-lg shadow-md border-4 border-green-500 w-3/4 h-auto" />
        </div>
      </div>
    </div>
  </div>
</section>



      <section id="play-to-train" ref={playToTrainRef} className="bg-blue-50 py-16">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl mb-8 text-black-800">Play to Train™ with GladesAI</h2>
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <Gamepad className="w-20 h-20 text-green-500 mx-auto mb-6" />
            <p className="text-xl text-gray-700 mb-6">
            Level up your neural network training with our immersive Play to Train AR/VR experience. 
            Contribute to model advancement through engaging gameplay, and earn rewards for your efforts!
            </p>
            <ul className="text-left text-lg text-gray-600 mb-8 space-y-2">
              <li>✓ <strong>Turn your AI training skills into real earnings! Complete challenges from top companies and get paid for your expertise.</strong></li>
              <li>✓ <strong>Create and sell your own AI models in our marketplace. Let your innovations benefit others and earn passive income</strong></li>
              <li>✓ <strong>Be among the first to test and use the latest AI models you've helped create. Shape the future of technology!</strong></li>
              
            </ul>
            <button className="bg-green-500 text-white px-8 py-4 rounded-full text-xl font-semibold hover:bg-purple-600 transition">
              Coming soon
            </button>
          </div>
          
        </div>
      </section>
{/* Marketplace Section */}

<section id="marketplace" className="container mx-auto py-16 px-4 text-center">
  <h2 className="text-4xl mb-4">Marketplace</h2>
  <p className="text-lg text-gray-600 mb-8">
    Exciting offerings are on the way! Stay tuned for the grand launch of our AI Marketplace.
  </p>
  
  <div className="relative grid grid-cols-1 md:grid-cols-3 gap-6 opacity-50 pointer-events-none">
    {Array.from({ length: 9 }).map((_, index) => (
      <div
        key={index}
        className="bg-white p-4 rounded-lg shadow-md text-center hover:shadow-lg transition"
      >
        <div className="w-12 h-12 bg-gray-300 rounded-full mx-auto mb-4"></div>
        <h3 className="text-lg font-semibold mb-2">Coming Soon</h3>
        <p className="text-sm text-gray-600 mb-4">
          Description:
        </p>
        <button
          className="bg-gray-400 text-white px-4 py-2 rounded-full cursor-not-allowed"
          disabled
        >
          Launch
        </button>
      </div>
    ))}
  </div>

</section>


      <GallerySection /> 

      <section id="resources" className="bg-blue-50 py-16">
  <div className="container mx-auto">
    {/* Roadmap Section */}
    <div className="bg-white p-12 rounded-lg mb-16 shadow-md">
      <HorizontalRoadmap />
    </div>

    {/* Resource Cards Grid */}
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
      {/* Technical Litepaper */}
      <ResourceCard 
        Icon={FileText}
        title="Technical Litepaper" 
        description="Dive deep into the technical aspects of our neural network architecture."
        buttonText="View Litepaper"
        buttonLink="https://github.com/Gattic/litepaper/wiki/Litepaper"
      />

      {/* Security Audit Reports */}
      <ResourceCard 
        Icon={ShieldCheck}
        title="Security Audit Reports" 
        description="Review our latest security audit report and privacy safeguards."
        buttonText="View Audit Report"
        buttonLink="https://your-security-audit-url.com"
      />

      {/* Glades Agent Terms of Service */}
      <ResourceCard 
        Icon={FileText}
        title="Glades Agent Terms of Service" 
        description="Read the terms of service before using our Glades Agent."
        buttonText="View Terms"
        isCollapsible={true}
        showAcceptConditions={true}
        extraContent={
          <div>
            <h4 className="font-bold mb-2">Glades Agent Terms of Service</h4>
            <p>1. Acceptance of Terms: By using the Glades Agent on Discord or Telegram platforms, you agree to be bound by these Terms of Service.</p>
            <p>2. Use of Service: The Glades Agent provides AI-powered features and commands through messaging platforms. The service is for informational purposes only and should not be relied upon for critical decisions.</p>
            <p>3. User Responsibilities: You are responsible for all activity using the Agent under your account. This includes proper use of commands, respecting rate limits, and maintaining appropriate security.</p>
            <p>4. Prohibited Actions: Users may not attempt to bypass command limitations, use the Agent for spam or harassment, exploit vulnerabilities, reverse engineer the Agent, or use it for any illegal purposes.</p>
            <p>5. Data Processing: Commands and messages sent to the Agent are processed to provide requested services. Message history may be temporarily stored for functionality. Do not share sensitive information through Agent commands.</p>
            <p>6. Service Availability: While we strive for high uptime, we do not guarantee uninterrupted service. The Agent may be unavailable during maintenance or updates.</p>
            <p>7. Command Results: AI predictions and analysis are for informational purposes only. Users are responsible for verifying results and making their own decisions.</p>
            <p>8. Platform Compliance: Usage must comply with Discord/Telegram terms of service and community guidelines. The Agent may be removed from platforms for violations.</p>
            <p>9. Disclaimer of Warranties: The Glades Agent is provided "as is" without any warranties of any kind.</p>
            <p>10. Limitation of Liability: We shall not be liable for any indirect, incidental, special, consequential or punitive damages, including but not limited to service interruptions or data accuracy.</p>
            <p>11. Changes to Terms: We reserve the right to modify these terms at any time. Continued use constitutes acceptance of changes.</p>
            <p>12. Contact: For questions about the Agent or these Terms, contact support@glades.ai</p>
          </div>
        }
      />
    </div>
  </div>
</section>



<ExtendedFAQSection 
  setFooterSection={setFooterSection}
  setIsFooterPageOpen={setIsFooterPageOpen}
/>


{/* Then, the Footer with links */}
<section className="bg-gradient-to-r from-blue-600 to-green-600 text-white py-16">
  <div className="container mx-auto text-center px-4">
    <h2 className="text-3xl md:text-4xl font-bold mb-4">
      Stay Updated
    </h2>
    <p className="text-lg mb-8 max-w-2xl mx-auto">
      Subscribe to receive updates about new features, AI insights, and platform news.
    </p>
    <form 
      onSubmit={handleSubscribe}
      className="max-w-md mx-auto flex flex-col md:flex-row gap-4"
    >
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter your email"
        className="px-4 py-3 rounded-lg flex-grow text-gray-800 focus:outline-none focus:ring-2 focus:ring-green-400"
        required
      />
      <button 
        type="submit"
        className="bg-green-500 hover:bg-green-600 px-8 py-3 rounded-lg transition duration-300 font-semibold"
      >
        Subscribe
      </button>
    </form>
    {subscribeStatus && (
      <p className="mt-4 text-green-300">{subscribeStatus}</p>
    )}
  </div>
</section>

<footer className="bg-blue-50 dark:bg-gray-900 text-gray dark:text-gray-400 py-4">
  <div className="container mx-auto text-center">
    <div className="flex flex-wrap justify-center items-center gap-4 md:gap-6">
      <p className="text-sm mb-0">
        &copy; 2024 GladesAI
      </p>
      <button 
        onClick={() => {
          setFooterSection('privacy');
          setIsFooterPageOpen(true);
        }} 
        className="text-sm text-green-400 hover:text-green-300 dark:text-green-500 dark:hover:text-green-400 transition"
      >
        Privacy
      </button>
      <button 
        onClick={() => {
          setFooterSection('terms');
          setIsFooterPageOpen(true);
        }} 
        className="text-sm text-green-400 hover:text-green-300 dark:text-green-500 dark:hover:text-green-400 transition"
      >
        Terms
      </button>
      <button 
  onClick={() => {
    setFooterSection('contact');
    setIsFooterPageOpen(true);
  }} 
  className="text-sm text-green-400 hover:text-green-300 dark:text-green-500 dark:hover:text-green-400 transition"
>
  Contact
</button>

<a 
  href="https://x.com/GladesAI" 
  target="_blank" 
  rel="noopener noreferrer"
  className="text-sm text-green-400 hover:text-green-300 dark:text-green-500 dark:hover:text-green-400 transition"
>
  X 
</a>

<a 
  href="https://github.com/Gattic/glades-ml" 
  target="_blank" 
  rel="noopener noreferrer"
  className="text-sm text-green-400 hover:text-green-300 dark:text-green-500 dark:hover:text-green-400 transition"
>
  GitHub
</a>

<a 
  href="https://discord.gg/6u3JartbQQ" 
  target="_blank" 
  rel="noopener noreferrer"
  className="text-sm text-green-400 hover:text-green-300 dark:text-green-500 dark:hover:text-green-400 transition"
>
  Discord
</a>

<a 
  href="https://t.me/gladesai" 
  target="_blank" 
  rel="noopener noreferrer"
  className="text-sm text-green-400 hover:text-green-300 dark:text-green-500 dark:hover:text-green-400 transition"
>
  Telegram
</a>

      <a 
        href="https://github.com/Gattic/litepaper/wiki/Litepaper" 
        target="_blank" 
        rel="noopener noreferrer"
        className="text-sm text-green-400 hover:text-green-300 dark:text-green-500 dark:hover:text-green-400 transition"
      >
        Litepaper
</a>
    </div>
  </div>
</footer>

<BackToTop /> 


{/* FooterPage component */}
<FooterPage
  isOpen={isFooterPageOpen}
  onClose={() => setIsFooterPageOpen(false)}
  initialSection={footerSection}

  
/>
    </div>

  );
}

export default GladesWebsite;
